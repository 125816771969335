.p-footer {
    background: #f9f9f9;
    padding: 10rem 8% 1rem;
}
.p-footer-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8rem;
}
.p-footer-1 {
    width: 35%;
}
.p-footer-1-img {
    width: 80px;
    margin-bottom: 1.2rem;
}
.p-footer-1-txt {
    font-size: 15px;
    color: #999999;
    max-width: 320px;
}
.p-footer-4 {
    width: 26%;
}
.p-footer-hd {
    color: #8C97AC;
    margin-bottom: 1.5rem;
    width: fit-content;
}
.p-footer-item {
    color: #999999;
    margin-bottom: 0.8rem;
    font-size: 15px;
    margin-top: 0
}
.p-footer-ctct {
    font-size: 15px;
    color: #FE6201;
    margin-bottom: 0;
}
.p-footer-copy {
    color: #8C97AC;
    font-size: 14px;
}
.p-footer-socials {
    color: #99cc33;
    display: flex;
    justify-content: space-between;
    /* width: 80px; */
    font-size: 14px;
    width: 40px;
}
.p-footer-link, .p-footer-link:hover {
    text-decoration: none;
}
.p-footer-link {
    transition: 0.2s;
}
.p-footer-link:hover {
    transform: scale(1.1);
}
.p-ftr-cpysec {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.p-footer-hd::after {
    content: "";
    border: 1px solid #99cc33;
    display: block;
    margin-top: 10px;
}
.p-footer-socials-mobile {
    display: none;
}
.frt-soc {
    cursor: pointer;
    color: #99cc33;
}
.p-footer-ctct:hover {
    text-decoration: underline;
    color: #FE6201;
}
.p-footer-4 a {
    text-decoration: none !important;
}
@media(max-width: 1000px) {
    .p-footer {
        padding: 5rem 4% 1rem;
    }
}
@media(max-width: 800px) {
    .p-footer-list {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 5rem;
    }
    .p-footer-1 {
        width: 100%;
    }
    .p-footer-1-txt {
        font-size: 15px;
        color: #999999;
        max-width: 320px;
        margin: 1rem auto 3rem;
    }
    .p-footer-list div:nth-child(2), .p-footer-list div:nth-child(3) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
    }
    .p-footer-hd::after {
        display: none;
    }
    .p-footer-4 {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .p-ftr-cpysec {
        justify-content: center;
    }
    .p-footer-socials {
        display: none;
    }
    .p-footer-socials-mobile {
        display: flex;
        margin: auto;
    }
    .p-footer-item {
        max-width: 250px;
    }
}